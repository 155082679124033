<template>
  <div class="flex gap-x-2 items-center p-2 group cursor-pointer">
    <slot :currentCity="currentCity">
      <icon-location class="w-3 h-auto opacity-50 group-hover:opacity-100 transition" />
      <span v-if="currentCity?.id" class="font-medium">
        {{ currentCity.name }}/{{ currentCity.uf }}
      </span>
      <span v-else class="font-medium">
        Todo o Brasil
      </span>
      <IconChevronDown class="opacity-50 group-hover:opacity-100 transition" />
    </slot>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user.js'

const userStore = useUserStore()
const { currentCity } = storeToRefs(userStore)

</script>